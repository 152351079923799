import { DOCUMENT, isPlatformServer } from '@angular/common'
import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  OnInit,
  Optional,
  PLATFORM_ID,
  Renderer2,
  TransferState,
} from '@angular/core'
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterOutlet } from '@angular/router'

import { AuthService } from '@auth0/auth0-angular'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, interval, of, Subscription } from 'rxjs'
import { exhaustMap, filter, mergeMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators'

import { FooterComponent, HeaderComponent, ProgressBarComponent } from '~/core/components'
import { DestroyDirective } from '~/core/directives'
import {
  APP_DEFAULT_LANGUAGE,
  COMPANY_FACADE,
  companyStateKey,
  ICompanyFacade,
  ICompanyState,
  IRouterFacade,
  IUiFacade,
  IUserFacade,
  IUserState,
  ROUTER_FACADE,
  UI_FACADE,
  USER_FACADE,
  userStateKey,
} from '~/core/features'
import { MetaTagService } from '~/core/services'
import { GuestPopupService } from '~/ui/guest-popup'
import { APP, SIGN_IN_PATH } from '~const/app.constant'
import { ENV } from '~env/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [DestroyDirective],
  host: { 'data-proximilar-version': ENV.appVersion },
  imports: [RouterOutlet, HeaderComponent, FooterComponent, ProgressBarComponent],
})
export class AppComponent implements OnInit {
  showLoading$ = new BehaviorSubject(false)

  private readonly destroy$ = inject(DestroyDirective).destroy$
  private guestMessaging$: Subscription | null = null

  constructor(
    @Inject(COMPANY_FACADE) private companyFacade: ICompanyFacade,
    @Inject(ROUTER_FACADE) private routerFacade: IRouterFacade,
    @Inject(USER_FACADE) private userFacade: IUserFacade,
    @Inject(UI_FACADE) public uiFacade: IUiFacade,
    @Inject(DOCUMENT) private document: Document,
    @Optional() private guestPopupService: GuestPopupService | null,
    @Optional() private authService: AuthService | null,
    @Inject(PLATFORM_ID) private platformID: Object,
    private transferState: TransferState,
    private metaTagService: MetaTagService,
    private translate: TranslateService,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.translate.setDefaultLang(APP_DEFAULT_LANGUAGE)

    afterNextRender(() => {
      this.initGuestListener()

      console.log('test 17.07', ENV)

      this.authService?.idTokenClaims$.pipe(takeUntil(this.destroy$)).subscribe(idTokenClaims => {
        console.log('idTokenClaims', idTokenClaims)
      })

      this.authService?.isAuthenticated$.pipe(takeUntil(this.destroy$)).subscribe(isAuthenticated => {
        console.log('isAuthenticated', isAuthenticated)
      })
    })
  }

  ngOnInit(): void {
    this.initCompanyStateListener()
    this.initUserStateListener()

    this.uiFacade.init()
    this.userFacade.initAuthUser()
    this.metaTagService.init()
    this.initThemeListener()
    this.initRouteListener()
    console.log(ENV)
  }

  private initCompanyStateListener(): void {
    if (isPlatformServer(this.platformID)) {
      this.companyFacade.state$
        .pipe(
          tap(state => this.transferState.set(companyStateKey, state)),
          takeUntil(this.destroy$)
        )
        .subscribe()
    } else {
      const state = this.transferState.get<ICompanyState | null>(companyStateKey, null)
      if (state) {
        this.companyFacade.initState(state)
      }
    }
  }

  private initUserStateListener(): void {
    if (isPlatformServer(this.platformID)) {
      this.userFacade.state$
        .pipe(
          tap(state => this.transferState.set(userStateKey, state)),
          takeUntil(this.destroy$)
        )
        .subscribe()
    } else {
      const state = this.transferState.get<IUserState | null>(userStateKey, null)
      if (state) {
        this.userFacade.initState(state)
      }
    }
  }

  private initThemeListener(): void {
    this.uiFacade.isDarkTheme$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isDarkTheme =>
        isDarkTheme
          ? this.renderer.addClass(this.document.body, 'dark-mode')
          : this.renderer.removeClass(this.document.body, 'dark-mode')
      )
  }

  private initRouteListener(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.uiFacade.startRequest()
      } else if (event instanceof RouteConfigLoadEnd) {
        this.uiFacade.endRequest()
      }
    })
  }

  private initGuestListener(): void {
    this.userFacade.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe(u => (u === null ? this.runGuestMessaging() : this.stopGuestMessaging()))
  }

  private runGuestMessaging(): void {
    this.stopGuestMessaging()

    this.guestMessaging$ = interval(APP.GUEST_DURATION * 1000)
      .pipe(
        withLatestFrom(this.authService?.isAuthenticated$ || of(false), this.routerFacade.isCompanyPage$),
        filter(([, isAuthenticated, isCompanyPage]) => !isAuthenticated && isCompanyPage),
        exhaustMap(() => this.guestPopupService?.openDialog() || of()),
        mergeMap(login => (login ? this.router.navigateByUrl(`/${SIGN_IN_PATH}`) : of(false))),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.runGuestMessaging()) // reset the interval after close the popup
  }

  private stopGuestMessaging(): void {
    if (this.guestMessaging$) {
      this.guestMessaging$.unsubscribe()
      this.guestMessaging$ = null
    }
  }
}
