import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog'

import { TranslateModule } from '@ngx-translate/core'

import { PopupComponent } from '~/shared/components'

import { IGuestPopupData } from './guest-popup.model'

@Component({
  selector: 'app-guest-popup',
  templateUrl: './guest-popup.component.html',
  styleUrl: './guest-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDialogClose, PopupComponent, TranslateModule, MatButtonModule],
})
export class GuestPopupComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IGuestPopupData) {}

  ngOnInit(): void {}
}
