import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { ApplicationConfig, importProvidersFrom, mergeApplicationConfig, TransferState } from '@angular/core'

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { provideAngularSvgIcon, SvgLoader } from 'angular-svg-icon'

import { ENV } from '~env/environment'

import { appStoreConfig } from './core/features'
import { GuestPopupModule } from './ui-components/guest-popup'
import { appConfig } from './app.config'
import { SvgBrowserLoader } from './svg-browser-loader.class'
import { TranslateBrowserLoader } from './translate-browser-loader.class'

const svgLoaderFactory = (http: HttpClient, transferState: TransferState): SvgBrowserLoader => {
  return new SvgBrowserLoader(http, transferState)
}

const translateLoaderFactory = (http: HttpClient): TranslateBrowserLoader => {
  return new TranslateBrowserLoader(http, './assets/i18n/', '.json')
}

const clientConfig: ApplicationConfig = {
  providers: [
    provideAngularSvgIcon({
      loader: {
        provide: SvgLoader,
        useFactory: svgLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    // provideAuth0({
    //   ...ENV.auth,
    //   authorizationParams: {
    //     ...ENV.auth.authorizationParams,
    //     redirectUri: window.location.origin,
    //     redirect_uri: window.location.origin,
    //   },
    //   httpInterceptor: {
    //     ...ENV.httpInterceptor,
    //   },
    // }),

    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: translateLoaderFactory,
          deps: [HttpClient],
        },
        useDefaultLang: true,
      }),
      AuthModule.forRoot({
        ...ENV.auth,
        // responseType: 'id_token',
        authorizationParams: {
          ...ENV.auth.authorizationParams,
          redirectUri: window.location.origin,
          redirect_uri: window.location.origin,
        },
        httpInterceptor: {
          ...ENV.httpInterceptor,
        },
      }),
      GuestPopupModule
    ),
  ],
}

export const config = mergeApplicationConfig(appConfig, clientConfig, appStoreConfig)
